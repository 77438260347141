/*
 * Polyfill stable language features.
 * It's recommended to use @babel/preset-env and browserslist
 * to only include the polyfills necessary for the target browsers.
 */
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'core-js/proposals/reflect-metadata';

/**
 * This file contains polyfills loaded on all browsers
 **/
(window as any).global = window;
